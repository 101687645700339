<template>
  <div>
    <v-card class='mx-3 pt-1 transparent' elevation=0 style='max-width: 1000px;'>
      <v-container>

        <v-card>
          <v-card-text>
            <v-list>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Task List</v-list-item-title>
                  <v-list-item-subtitle>These tasks may be used for scheduling purposes.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.TaskList.open() }'><v-icon class='mr-2'>mdi-view-list</v-icon>Tasks</v-btn>
                  <TaskList ref='TaskList' />
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Preset Taskflows</v-list-item-title>
                  <v-list-item-subtitle>Create taskflows that can be quick-selected in job cards.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.TaskflowList.open() }'><v-icon class='mr-2'>mdi-view-list</v-icon>Presets</v-btn>
                  <TaskflowList ref='TaskflowList' />
                </v-list-item-action>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>

      </v-container>
    </v-card>


  </div>
</template>

<style lang="scss">
</style>


<script>
import { mapActions } from 'vuex';
import TaskList from '@/components/Dialogs/TaskList'
import TaskflowList from '@/components/Dialogs/TaskflowList'
export default {
  name: 'Schedule',
  components: {
    TaskList, TaskflowList
  },
  watch:{
    $route: { handler(){ let t = `Schedule Settings`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE"]),
    
  },
  mounted(){
  }
}
</script>
