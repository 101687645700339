var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Tasks",
      "load-items": _vm.loadTasks,
      "add-button": "",
      "item-key": "xid",
      "item-text": "title",
      "sortable": ""
    },
    on: {
      "add-item": _vm.addTask,
      "sort": _vm.sortTasks
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "float-left primary--text",
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editDuration(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-clock-outline")]), _vm._v(_vm._s(item.duration))], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editTask(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteTask(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "error--text"
        }, [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }