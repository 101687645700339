<template>
  <div>

    <ListModal
      ref='Modal' title='Preset Taskflows'
      :load-items='loadItems' add-button @add-item='addItem'
      item-key='xid' item-text='title'
      sortable @sort='sortItems'
    >
      <template #actions='{item}'>
        <v-btn x-small fab text @click='openTaskflow(item)' class='float-left'><v-icon class='primary--text'>mdi-source-merge</v-icon></v-btn>
          <v-btn x-small fab text @click='editItem(item)' class='float-left'><v-icon class='primary--text'>mdi-pencil</v-icon></v-btn>
          <v-btn x-small fab text @click='deleteItem(item)' class='float-left'><v-icon class='error--text'>mdi-trash-can</v-icon></v-btn>
      </template>
    </ListModal>

    <div :key="taskflowId">
      <!-- This key refreshes the taskflow instance when the ID changes  -->
      <Taskflow ref="Taskflow" type="preset" :id="taskflowId" />
    </div>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
//import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'
import Taskflow from '@/components/Dialogs/Taskflow'

export default {
  name: 'TaskflowList',
  components: {
    ListModal, Taskflow,
  },

  props: {},

  data(){
    return {
      taskflowId: '',
    }
  },

  computed: {},

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['CONFIRM', 'PROMPT']),

    openTaskflow({xid}){
      this.taskflowId = xid;
      this.$nextTick(() => { this.$refs.Taskflow.open(); })
    },

    open(){
      this.$refs.Modal.open();
    },

    loadItems(){
      return this.API({ method: 'GET', endpoint: 'taskflows' });
    },

    async addItem(){
      let title = await this.PROMPT({ title: 'New Taskflow', message: 'Name of the new Taskflow:' });
      let rq = this.API({
        method: 'POST',
        endpoint: 'taskflows',
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async editItem(item){
      let title = await this.PROMPT({ title: 'Rename Taskflow', message: 'New name:', placeholder: item.title });
      if(title === item.title) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `taskflows/${item.xid}`,
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async deleteItem(item){
      await this.CONFIRM({ title: 'Delete Taskflow', message: `Delete "${item.title}"?` });
      let rq = this.API({
        method: 'DELETE',
        endpoint: `taskflows/${item.xid}`
      });
      this.$refs.Modal.load(rq, true);
    },

    sortItems(sort_order){
      let rq = this.API({
        method: 'POST',
        endpoint: 'taskflows/sort',
        data: { sort_order }
      });
      this.$refs.Modal.load(rq, true);
    },

  },
}
</script>
