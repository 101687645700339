<template>
  <div>

    <ListModal
      ref='Modal' title='Tasks'
      :load-items='loadTasks' add-button @add-item='addTask'
      item-key='xid' item-text='title'
      sortable @sort='sortTasks'
    >
      <template #actions='{item}'>
          <v-btn text @click='editDuration(item)' class='float-left primary--text'><v-icon class="mr-1" small>mdi-clock-outline</v-icon>{{ item.duration }}</v-btn>
          <v-btn icon @click='editTask(item)' class='float-left'><v-icon class='primary--text'>mdi-pencil</v-icon></v-btn>
          <v-btn icon @click='deleteTask(item)' class='float-left'><v-icon class='error--text'>mdi-trash-can</v-icon></v-btn>
      </template>
    </ListModal>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
//import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'

export default {
  name: 'TaskList',
  components: {
    ListModal,
  },

  props: {},

  data(){
    return {}
  },

  computed: {},

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['CONFIRM', 'PROMPT']),

    open(){
      this.$refs.Modal.open();
    },

    loadTasks(){
      return this.API({ method: 'GET', endpoint: 'taskTypes' });
    },

    async addTask(){
      let title = await this.PROMPT({ title: 'New Task Type', message: 'Name of the new Task Type:' });
      let rq = this.API({
        method: 'POST',
        endpoint: 'taskTypes',
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async editTask(item){
      let title = await this.PROMPT({ title: 'Rename Task Type', message: 'New name:', placeholder: item.title });
      if(title === item.title) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `taskTypes/${item.xid}`,
        data: { title }
      });
      this.$refs.Modal.load(rq, true);
    },

    async editDuration(item){
      let duration = await this.PROMPT({ title: 'Task Duration', message: 'Duration in minutes:', placeholder: item.duration });
      if(duration === item.duration) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `taskTypes/${item.xid}`,
        data: { duration }
      });
      this.$refs.Modal.load(rq, true);
    },

    async deleteTask(item){
      await this.CONFIRM({ title: 'Delete Task Type', message: `Delete "${item.title}"?` });
      let rq = this.API({
        method: 'DELETE',
        endpoint: `taskTypes/${item.xid}`
      });
      this.$refs.Modal.load(rq, true);
    },

    sortTasks(sort_order){
      let rq = this.API({
        method: 'POST',
        endpoint: 'taskTypes/sort',
        data: { sort_order }
      });
      this.$refs.Modal.load(rq, true);
    },

  },
}
</script>
