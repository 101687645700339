var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Preset Taskflows",
      "load-items": _vm.loadItems,
      "add-button": "",
      "item-key": "xid",
      "item-text": "title",
      "sortable": ""
    },
    on: {
      "add-item": _vm.addItem,
      "sort": _vm.sortItems
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "x-small": "",
            "fab": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openTaskflow(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-source-merge")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "x-small": "",
            "fab": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "x-small": "",
            "fab": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "error--text"
        }, [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  }), _c('div', {
    key: _vm.taskflowId
  }, [_c('Taskflow', {
    ref: "Taskflow",
    attrs: {
      "type": "preset",
      "id": _vm.taskflowId
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }